<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ updateMode == false ? $t('message.addBankAccount') : $t('message.updateBankAccount') + ": " + bankAccountName }}</span>
                <HelpPopUpV2 help-page-component="AddBankAccount" :light="true" :no-padding="true"/>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="bankAccountForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.accountOwner') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-autocomplete
                                    :items="allSuppliers"
                                    :rules="[...validationRules.required]"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Supplier.title"
                                    item-value="Supplier.id"
                                    solo
                                    v-model="Bank__supplier_id"
                            >
                                <template v-slot:item="{ item }">
                                    <span>{{ item.Supplier.otsname != null ? item.Supplier.otsname : item.Supplier.title }}</span>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.profileName') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                    :value="Bank__displayname"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Bank__displayname = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.currency') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-select
                                    :items="allCurrencies"
                                    :rules="[...validationRules.required]"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Currency.code"
                                    item-value="Currency.id"
                                    solo
                                    v-model="Bank__currency_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.bankName') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                    :value="Bank__title"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Bank__title = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.bankAddress') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-textarea
                                    :rules="[...validationRules.plainTextWithNumbers]"
                                    :value="Bank__address"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    rows="3"
                                    solo
                                    @change="Bank__address = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.bankCountry') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-select
                                    :rules="[...validationRules.required]"
                                    :items="allCountries"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Country.name"
                                    item-value="Country.id"
                                    solo
                                    v-model="Bank__country_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.accountBeneficiary') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainText]"
                                    :value="Bank__beneficiary"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Bank__beneficiary = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.accountNumber') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                    :value="Bank__account"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Bank__account = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.accountSwiftCode') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainTextWithNumbers]"
                                    :value="Bank__swiftcode"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Bank__swiftcode = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.accountAbaNumber') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainTextWithNumbers]"
                                    :value="Bank__abano"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Bank__abano = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.agentBank') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainTextWithNumbers]"
                                    :value="Bank__agent"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Bank__agent = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.agentBankAccountNumber') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainTextWithNumbers]"
                                    :value="Bank__beneficiaryaccount"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Bank__beneficiaryaccount = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.agentBankSwiftCode') }}</v-flex>
                        <v-flex xs8 lg8 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainTextWithNumbers]"
                                    :value="Bank__agentswiftcode"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Bank__agentswiftcode = $event"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addBankAccount()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveBankAccount()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {validationRules} from "@/store/modules/appic/constants";
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "AddBankAccount",
    components: { HelpPopUpV2 },
    props: ['bankAccountId','bankAccountName','dialog','updateMode','dialog-closed','update-done','create-done'],
    data() {
        return {
            bankAccountType: 0,
            dialogs: {
                error: false,
                error_message: ""
            },
            edit_dialog: false,
            loading: {
                add: false,
                get: false,
                save: false
            },
            // rules: {
            //     currency: v => !!v || this.$t('message.required'),
            //     supplier: v => !!v || this.$t('message.required')
            // },
            validForm: true
        }
    },
    computed: {
        validationRules() {
            return validationRules
        },
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('bankaccount',{
            Bank__abano: 'Bank.abano',
            Bank__account: 'Bank.account',
            Bank__address: 'Bank.address',
            Bank__agent: 'Bank.agent',
            Bank__agentswiftcode: 'Bank.agentswiftcode',
            Bank__beneficiary: 'Bank.beneficiary',
            Bank__beneficiaryaccount: 'Bank.beneficiaryaccount',
            Bank__country_id: 'Bank.country_id',
            Bank__currency_id: 'Bank.currency_id',
            Bank__displayname: 'Bank.displayname',
            Bank__id: 'Bank.id',
            Bank__office_id: 'Bank.office_id',
            Bank__supplier_id: 'Bank.supplier_id',
            Bank__swiftcode: 'Bank.swiftcode',
            Bank__title: 'Bank.title'
        }, 'statePrefix'),
        ...mapGetters('country',{
            allCountries: 'allCountries'
        }),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('supplier',{
            allSuppliers: 'allSuppliers'
        }),
        ...mapGetters([
            'currencies',
            'offices',
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        ...mapActions('bankaccount',{
            createBankAccount: 'createBankAccount',
            getBankAccountToUpdateById: 'getBankAccountToUpdateById',
            resetBankAccountToUpdate: 'resetBankAccountToUpdate',
            resetCurrentState: 'resetCurrentState',
            updateBankAccount: 'updateBankAccount'
        }),
        ...mapActions('country',{
            getAllCountries: ' getAllCountries'
        }),
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        ...mapActions('supplier',{
            getAllSuppliers: 'getAllSuppliers'
        }),
        addBankAccount () {
            if (this.$refs.bankAccountForm.validate()) {
                this.loading.add = true
                this.createBankAccount()
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.bankAccountAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.bankAccountNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.bankAccountNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.bankAccountNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        saveBankAccount () {
            if (this.$refs.bankAccountForm.validate()) {
                this.loading.save = true
                this.updateBankAccount()
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.bankAccountUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.bankAccountNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.bankAccountNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.bankAccountNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog (value) {
            if(value == true){
                if(this.updateMode == true && typeof this.bankAccountId === 'number') {
                    this.loading.get = true
                    this.getBankAccountToUpdateById(this.bankAccountId)
                        .then(()=>{
                            if(this.Bank__supplier_id !== null && this.Bank__office_id == null) this.bankAccountType = 0
                            if(this.Bank__office_id != null && this.Bank__supplier_id == null ) this.bankAccountType = 1
                            this.loading.get = false
                        })
                } else {
                    this.resetCurrentState()
                }
            } else {
                if(this.updateMode == true) {
                    this.resetBankAccountToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    },
    mounted() {
        if(this.allCountries.length == 0) this.getAllCountries()
        if(this.allCurrencies.length == 0) this.getAllCurrencies()
        if(this.allSuppliers.length == 0) this.getAllSuppliers()
    }

}
</script>

<style>

</style>